import React from 'react';
import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import firebase from 'firebase';

import Home from './pages/Home';
import About from './pages/About';
import SignIn from './pages/SignIn';
import Dashboard from './pages/Dashboard';
import { render } from '@testing-library/react';

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyCffcKQxrhrVk4uo0HSkR-QNSIcWvC8_64",
  authDomain: "spreadly-core.firebaseapp.com",
  databaseURL: "https://spreadly-core.firebaseio.com",
  projectId: "spreadly-core",
  storageBucket: "spreadly-core.appspot.com",
  messagingSenderId: "723367478518",
  appId: "1:723367478518:web:ee707a5db495fb543198d7",
  measurementId: "G-HM7ZESYJ8X"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const INITIAL_STATE = {
  isLoggedIn: false
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }
  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          isLoggedIn: true,
        });
      } else {
        this.setState({
          isLoggedIn: false,
        })
      }
    });
  }

  render() {
    return (
      <Router>
        <div>
          <div className="nav-list">
            <div className="nav-list-item">
              <Link to="/">Home</Link>
            </div>
            <div className="nav-list-item">
              <Link to="/about">About</Link>
            </div>
            <div className="spacer">
              &nbsp;
            </div>
            {!this.state.isLoggedIn &&
              <div className="nav-list-item">
                <Link to="/signin">Sign In</Link>
              </div>
            }
            {this.state.isLoggedIn &&
              <div className="logged-in nav-list-item">
                <Link to="/dashboard">Dashboard</Link>
              </div>
            }
          </div>
  
          {/*
            A <Switch> looks through all its children <Route>
            elements and renders the first one whose path
            matches the current URL. Use a <Switch> any time
            you have multiple routes, but you want only one
            of them to render at a time
          */}
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/signin">
              <SignIn />
            </Route>
            <Route path="/dashboard">
              <Dashboard />
            </Route>
          </Switch>
        </div>
      </Router>
    );  
  }
}

export default App;
