import React from 'react';
import { Redirect } from 'react-router-dom';
import firebase from 'firebase';
import { Button, Dialog, TextInputField, Table, SelectField, Pane, toaster } from 'evergreen-ui';

const INITIAL_STATE = {
  email: '',
  redirect: null,
  menuItems: [],
  lastOrder: 0,
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      this.setState({
        email: user.email,
      });
      this.populateExistingMenuItems(user.uid);
    });
  }

  populateExistingMenuItems = (userUid) => {
    toaster.notify('You are using an Alpha version of Spreadly Web. If you experience issues, please try reloading the page')
    firebase.firestore().collection('clients')
    .doc(userUid)
    .get().then((doc) => {
      console.log(doc.data())
      this.setState({
        restaurantName: doc.data() ? doc.data().name : '',
      })
    })
    firebase.firestore().collection('clients')
    .doc(userUid)
    .collection('menu')
    .orderBy('order')
    .onSnapshot((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        this.setState((prevState) => ({
          menuItems: [...prevState.menuItems, [doc.data(), doc.id]],
          lastOrder: prevState.lastOrder + 1,
        }));
      })
    })
  }

  signOut = () => {
    firebase.auth().signOut()
    .then(() => {
      this.setState({
        redirect: '/',
      })
    })
  }

  saveRestaurantDetails = () => {
    firebase.auth().onAuthStateChanged((user) => {
      firebase.firestore().collection('clients')
      .doc(user.uid)
      .set({
        name: this.state.restaurantName,
      })
    })
  }

  submitMenuItem = () => {
    firebase.auth().onAuthStateChanged((user) => {
      firebase.firestore().collection('clients')
      .doc(user.uid)
      .collection('menu').add({
        description: this.state.newDescription,
        gf: this.state.newGf ?? false,
        ingredients: this.state.newIngredients.split(','),
        name: this.state.newName,
        order: this.state.lastOrder,
        pescatarian: this.state.newPescatarian ?? false,
        price: this.state.newPrice,
        sides: this.state.newSides.split(','),
        type: this.state.newType,
        vegan: this.state.newVegan ?? false,
        vegetarian: this.state.newVegetarian ?? false,
      });
    });
  }

  saveEditedMenuItem = () => {
    firebase.auth().onAuthStateChanged((user) => {
      firebase.firestore().collection('clients')
      .doc(user.uid)
      .collection('menu')
      .doc(this.state.activeId).set({
        description: this.state.editDescription,
        gf: this.state.editGf ?? false,
        ingredients: this.state.editIngredients.split(','),
        name: this.state.editName,
        order: this.state.lastOrder,
        pescatarian: this.state.editPescatarian ?? false,
        price: this.state.editPrice,
        sides: this.state.editSides.split(','),
        type: this.state.editType,
        vegan: this.state.editVegan ?? false,
        vegetarian: this.state.editVegetarian ?? false,
      })
    })
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
      <div className="container">
        <h2>Dashboard {this.state.restaurantName ? `(${this.state.restaurantName})` : ''}</h2>
        <Button onClick={() => this.signOut()}>Sign out</Button>
        <h3>Restaurant Details</h3>
        <TextInputField
          name="restaurantName"
          placeholder="Restaurant Name"
          value={this.state.restaurantName ?? ''}
          onChange={e => {this.setState({restaurantName: e.target.value})}}
        />
        <Button onClick={() => this.saveRestaurantDetails()}>Save</Button>
        <h3>Menu Items</h3>
        <Pane
          width={"100%"}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Table width={"100%"} className="menu-table">
            <Table.Head>
              <Table.TextHeaderCell>
                Name
              </Table.TextHeaderCell>
              <Table.TextHeaderCell>
                Description
              </Table.TextHeaderCell>
              <Table.TextHeaderCell>
                Ingredients
              </Table.TextHeaderCell>
              <Table.TextHeaderCell>
                Price
              </Table.TextHeaderCell>
              <Table.TextHeaderCell>
                Type
              </Table.TextHeaderCell>
              <Table.TextHeaderCell>
                Sides
              </Table.TextHeaderCell>
              <Table.TextHeaderCell flexBasis={80} flexShrink={0} flexGrow={0}>
                Gluten Free
              </Table.TextHeaderCell>
              <Table.TextHeaderCell flexBasis={80} flexShrink={0} flexGrow={0}>
                Pescatarian
              </Table.TextHeaderCell>
              <Table.TextHeaderCell flexBasis={80} flexShrink={0} flexGrow={0}>
                Vegan
              </Table.TextHeaderCell>
              <Table.TextHeaderCell flexBasis={80} flexShrink={0} flexGrow={0}>
                Vegetarian
              </Table.TextHeaderCell>
            </Table.Head>
            <Table.VirtualBody height={240}>
              {this.state.menuItems.map(menuItem => (
                <Table.Row
                  key={menuItem[0].name}
                  isSelectable
                  onSelect={() => {
                    this.setState({
                      activeId: menuItem[1],
                      editDescription: menuItem[0].description,
                      editGf: menuItem[0].gf,
                      editIngredients: menuItem[0].ingredients,
                      editName: menuItem[0].name,
                      editPescatarian: menuItem[0].pescatarian,
                      editPrice: menuItem[0].price,
                      editSides: menuItem[0].sides,
                      editType: menuItem[0].type,
                      editVegan: menuItem[0].vegan,
                      editVegetarian: menuItem[0].vegetarian,
                      editDialogVisible: true,
                    }
                  )}}>
                  <Table.TextCell>{menuItem[0].name}</Table.TextCell>
                  <Table.TextCell>{menuItem[0].description}</Table.TextCell>
                  <Table.TextCell>
                    {menuItem[0].ingredients.map((ingredient) => (
                      <span>{ingredient} </span>
                    ))}
                  </Table.TextCell>
                  <Table.TextCell>{menuItem[0].price}</Table.TextCell>
                  <Table.TextCell>{menuItem[0].type}</Table.TextCell>
                  <Table.TextCell>
                    {menuItem[0].sides.map((side) => (
                      <span>{side} </span>
                    ))}
                  </Table.TextCell>
                  <Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0}>{menuItem[0].gf ? <span>✅</span> : <span>🚫</span>}</Table.TextCell>
                  <Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0}>{menuItem[0].pescatarian ? <span>✅</span> : <span>🚫</span>}</Table.TextCell>
                  <Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0}>{menuItem[0].vegan ? <span>✅</span> : <span>🚫</span>}</Table.TextCell>
                  <Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0}>{menuItem[0].vegetarian ? <span>✅</span> : <span>🚫</span>}</Table.TextCell>
                </Table.Row>
              ))}
            </Table.VirtualBody>
          </Table>
        </Pane>
        <Button onClick={() => this.setState({ createDialogVisible: true })}>Create new menu item</Button>
        <Pane>
          <Dialog
            isShown={this.state.createDialogVisible}
            title="Create new menu item"
            onConfirm={() => {
              this.setState({ createDialogVisible: false });
              this.submitMenuItem();
            }}
            confirmLabel="Create"
            onCloseComplete={() => {
              this.setState({ createDialogVisible: false });
            }}
          >
            <TextInputField
              name="name"
              label="Name"
              placeholder="Sarah's Famous Tacos"
              isRequired
              value={this.state.newTitle}
              onChange={e => this.setState({ newName: e.target.value })}
            />
            <TextInputField
              name="description"
              label="Description"
              placeholder="Most of us grew up eating ground beef tacos in a crispy corn shell or a soft flour tortilla"
              isRequired
              value={this.state.newDescription}
              onChange={e => this.setState({ newDescription: e.target.value })}
            />
            <TextInputField
              name="ingredients"
              label="Ingredients"
              placeholder="shredded cheese,shredded lettuce,chopped tomatoes,diced onion"
              validationMessage="Make sure to include a comma in between each ingredient"
              value={this.state.newIngredients}
              onChange={e => this.setState({ newIngredients: e.target.value.toLowerCase() })}
            />
            <TextInputField
              name="price"
              label="Price"
              placeholder="$10.30"
              value={this.state.newPrice}
              onChange={e => this.setState({ newPrice: e.target.value })}
            />
            <TextInputField
              name="type"
              label="Type"
              placeholder="Entrees"
              value={this.state.newType}
              onChange={e => this.setState({ newType: e.target.value })}
            />
            <TextInputField
              name="sides"
              label="Sides"
              placeholder="beans,rice"
              validationMessage="Make sure to include a comma in between each side"
              value={this.state.newSides}
              onChange={e => this.setState({ newSides: e.target.value.toLowerCase() })}
            />
            <SelectField
              value={this.state.newGf ?? false}
              label="Gluten Free?"
              onChange={e => this.setState({ newGf: e.target.value })}
            >
              <option value={false}>No</option>
              <option value={true} selected>Yes</option>
            </SelectField>
            <SelectField
              value={this.state.newPescatarian ?? false}
              label="Pescatarian?"
              onChange={e => this.setState({ newPescatarian: e.target.value })}
            >
              <option value={false}>No</option>
              <option value={true} selected>Yes</option>
            </SelectField>
            <SelectField
              value={this.state.newVegan ?? false}
              label="Vegan?"
              onChange={e => this.setState({ newVegan: e.target.value })}
            >
              <option value={false}>No</option>
              <option value={true} selected>Yes</option>
            </SelectField>
            <SelectField
              value={this.state.newVegetarian ?? false}
              label="Vegetarian?"
              onChange={e => this.setState({ newVegetarian: e.target.value })}
            >
              <option value={false}>No</option>
              <option value={true} selected>Yes</option>
            </SelectField>
          </Dialog>
        </Pane>
        <Pane>
          <Dialog
            isShown={this.state.editDialogVisible}
            title="Edit menu item"
            onConfirm={() => {
              this.setState({ editDialogVisible: false });
              this.saveEditedMenuItem();
            }}
            confirmLabel="Save"
            onCloseComplete={() => {
              this.setState({ editDialogVisible: false });
            }}
          >
            <TextInputField
              name="name"
              label="Name"
              placeholder="Sarah's Famous Tacos"
              isRequired
              value={this.state.editName}
              onChange={e => this.setState({ editName: e.target.value })}
            />
            <TextInputField
              name="description"
              label="Description"
              placeholder="Most of us grew up eating ground beef tacos in a crispy corn shell or a soft flour tortilla"
              isRequired
              value={this.state.editDescription}
              onChange={e => this.setState({ editDescription: e.target.value })}
            />
            <TextInputField
              name="ingredients"
              label="Ingredients"
              placeholder="shredded cheese,shredded lettuce,chopped tomatoes,diced onion"
              validationMessage="Make sure to include a comma in between each ingredient"
              value={this.state.editIngredients}
              onChange={e => this.setState({ editIngredients: e.target.value.toLowerCase() })}
            />
            <TextInputField
              name="price"
              label="Price"
              placeholder="$10.30"
              value={this.state.editPrice}
              onChange={e => this.setState({ editPrice: e.target.value })}
            />
            <TextInputField
              name="type"
              label="Type"
              placeholder="Entrees"
              value={this.state.editType}
              onChange={e => this.setState({ editType: e.target.value })}
            />
            <TextInputField
              name="sides"
              label="Sides"
              placeholder="beans,rice"
              validationMessage="Make sure to include a comma in between each side"
              value={this.state.editSides}
              onChange={e => this.setState({ editSides: e.target.value.toLowerCase() })}
            />
            <SelectField
              value={this.state.editGf ?? false}
              label="Gluten Free?"
              onChange={e => this.setState({ editGf: e.target.value })}
            >
              <option value={false}>No</option>
              <option value={true} selected>Yes</option>
            </SelectField>
            <SelectField
              value={this.state.editPescatarian ?? false}
              label="Pescatarian?"
              onChange={e => this.setState({ editPescatarian: e.target.value })}
            >
              <option value={false}>No</option>
              <option value={true} selected>Yes</option>
            </SelectField>
            <SelectField
              value={this.state.editVegan ?? false}
              label="Vegan?"
              onChange={e => this.setState({ editVegan: e.target.value })}
            >
              <option value={false}>No</option>
              <option value={true} selected>Yes</option>
            </SelectField>
            <SelectField
              value={this.state.editVegetarian ?? false}
              label="Vegetarian?"
              onChange={e => this.setState({ editVegetarian: e.target.value })}
            >
              <option value={false}>No</option>
              <option value={true} selected>Yes</option>
            </SelectField>
          </Dialog>
        </Pane>
      </div>
    );
  }
}

export default Dashboard;
