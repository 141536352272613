import React from 'react';
import { Heading } from 'evergreen-ui';

import './styles.css';
import restaurantPic from '../../assets/restaurant.jpg';

function Home() {
  return (
    <div className="container">
      <img className="hdr-image" src={restaurantPic} />
      <h1 className="hdr">Spreadly</h1>
      <p>Intuitive touchless menus to counteract the spread</p>
    </div>
  );
}

export default Home;
